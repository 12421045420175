import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowUpRight } from 'lucide-react';
import Blog3HeaderD from '../../assets/blogs/blog3/HSCodeMainD.png';
import Blog1HeaderM from '../../assets/blogs/blog1/blog1headerM.png';
import HSCodeD from '../../assets/blogs/blog3/HSCodeD.png';
import HSCodeM from '../../assets/blogs/blog3/HSCodeM.png';
import { blogPosts } from '../../pages/BlogData';
import BackgroundPNG from '../../assets/blogs/blog2/future_bg.png'
import Dialog from '../../components/dialog'
import DemoForm from '../../components/demoForm'
import { ReactComponent as LearnMore } from '../../assets/learn_more_icon.svg'
import BlogSuggestions from './BlogSuggestions';


const Blog3 = ({ post }) => {
    const [showDialog, setShowDialog] = useState(false)
    const relatedPostId = 2;
    const relatedPost = blogPosts.find(p => p.id === relatedPostId);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="min-h-screen bg-white">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <Link to="/blogs" className="inline-flex items-center text-[#3B3C4A] hover:text-indigo-700 mb-8">
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    Back to blogs
                </Link>

                <div className="flex flex-wrap gap-2 mb-4">
                    {post.categories.map((category, idx) => (
                        <span
                            key={idx}
                            className={`text-xs px-3 py-1 rounded-full ${category === 'AIforTradeCompliance' ? 'bg-[#EDF4FF] text-[#1619CA]' :
                                category === 'HSCodeAutomation' ? 'bg-[#FEECFB] text-[#A5088C]' :
                                    category === 'GlobalTradeSolutions' ? 'bg-[#FEF0F4] text-[#D50B3E]' :
                                        'bg-gray-100 text-gray-800'
                                }`}
                        >
                            {category}
                        </span>
                    ))}
                </div>

                <h1 className="text-4xl font-bold text-gray-900 mb-4">{post.title}</h1>

                <div className="flex items-center text-sm text-gray-500 mb-8">
                    <span>{post.date}</span>
                </div>
                <img
                    src={Blog3HeaderD}
                    alt={post.title}
                    className="hidden md:block w-full h-[585px] object-cover rounded-lg mb-8"
                />

                <img
                    src={Blog1HeaderM}
                    alt={post.title}
                    className="block md:hidden w-full h-[240px] object-cover rounded-lg mb-8"
                />

                <div className="prose max-w-none">
                    <p className="text-gray-600 mb-6">
                        In global trade, <span className='text-md font-bold text-[#3b3c4a]'>  Harmonized System (HS) codes </span> serve as the  <span className='text-md font-bold text-[#3b3c4a]'> backbone of customs classification,</span> ensuring that products are correctly identified and taxed. These internationally recognized codes, developed by the World Customs Organization (WCO), help   <span className='text-md font-bold text-[#3b3c4a]'> standardize trade operations </span> across countries. Every product that crosses international borders must be assigned an HS code, which determines its applicable duties, taxes, and regulatory requirements.
                    </p>

                    <p className="text-gray-600 mb-6">
                        However, misclassification of HS codes can lead to serious financial and operational setbacks. Incorrect classification-whether accidental or intentional-can result in customs delays, penalties, increased scrutiny, and unexpected costs. As global trade regulations become more stringent, <span className='text-md font-bold text-[#3b3c4a]'>  businesses must ensure accurate product classification </span>  to avoid compliance risks.
                    </p>

                    <div className='bg-[#F6F6F6]'>
                        <a href="/product/hscode" className="block w-full md:no-underline">
                            <div className="flex justify-between items-center px-5 py-3  rounded-md border border-[#D6D6D6]">
                                <h2 className="text-2xl font-bold m-0">What Makes Vidatt Ai Assist Different ?</h2>
                                <div className="border rounded-lg border-[#D6D6D6] bg-white w-10 h-10 hidden md:flex items-center justify-center">
                                    <LearnMore className="w-1.5 h-1.5" />
                                </div>
                            </div>
                        </a>
                    </div>

                    <h2 className="text-2xl font-semibold mb-4">Intelligent Automation That Adapts to Your Business</h2>
                    <p className="text-gray-600 mb-6">
                        Vidatt Ai Assist isn’t just another automation tool. It’s designed to think, learn, and adapt - helping businesses cut down on manual work, avoid errors, and make smarter decisions in real time. Unlike traditional automation, which follows pre-defined rules, Vidatt Ai Assist understands patterns, analyzes context, and continuously improves based on real-world interactions. From finance teams looking to simplify invoice processing to sales teams wanting better insights, Vidatt Ai Assist fits right into your workflow with minimal setup and maximum impact.
                    </p>

                    <div className="">
                        <h2 className="text-xl font-semibold text-gray-900">
                            The Risks of HS Code Misclassification
                        </h2>
                        <p className="mt-2 text-gray-700">
                            HS code misclassification isn’t just an inconvenience – it can create significant disruptions for businesses. Some of the major consequences include:
                        </p>
                        <ul className="mt-4 list-disc list-inside space-y-2 text-gray-700">
                            <li>
                                <span className="font-semibold">Financial Penalties & Fines –</span> Customs authorities impose hefty fines on businesses that incorrectly classify goods, leading to unnecessary financial losses.
                            </li>
                            <li>
                                <span className="font-semibold">Customs Delays & Seizures –</span> Misclassified shipments often require additional inspections, delaying supply chains and impacting delivery timelines.
                            </li>
                            <li>
                                <span className="font-semibold">Legal & Compliance Risks –</span> Some misclassifications can be interpreted as trade violations, exposing businesses to regulatory scrutiny or even legal actions.
                            </li>
                            <li>
                                <span className="font-semibold">Overpayment or Underpayment of Duties –</span> Incorrect HS codes may result in higher tariffs or potential disputes over underpaid duties.
                            </li>
                            <li>
                                <span className="font-semibold">Reputational Damage –</span> Companies that repeatedly misclassify goods may face loss of trust from trade partners and increased oversight from customs authorities.
                            </li>
                        </ul>
                    </div>

                    <div className='bg-[#F6F6F6]'>
                        <a href="/product/hscode" className="block w-full md:no-underline">
                            <div className="flex justify-between items-center px-5 py-3  rounded-md border border-[#D6D6D6]">
                                <h2 className="text-2xl font-bold m-0">Know what’s Incredible about Vidatt’s HS code Classification</h2>
                                <div className="border rounded-lg border-[#D6D6D6] bg-white w-10 h-10 hidden md:flex items-center justify-center">
                                    <LearnMore className="w-1.5 h-1.5" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <img
                        src={HSCodeD}
                        alt={post.title}
                        className="hidden md:block w-full h-[310px] object-cover rounded-lg mb-8"
                    />

                    <img
                        src={HSCodeM}
                        alt={post.title}
                        className="block md:hidden w-full h-[380px] object-cover rounded-lg mb-8"
                    />
                    <h2 className="text-2xl font-semibold mb-4">How Vidatt AI Assist Solves HS Code Classification Challenges</h2>
                    <p className="text-gray-600">
                        At Vidatt, we understand that accurate and efficient HS code classification is essential for smooth global trade. That’s why we developed Vidatt AI Assist, an advanced AI-powered trade compliance tool designed to eliminate misclassification errors and ensure regulatory adherence.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">AI-Powered Accuracy & Compliance</h2>
                    <p className="text-[#3B3C4A]">
                        Vidatt AI Assist  <span className='text-md font-bold text-[#3b3c4a]'>leverages Generative AI to analyze product descriptions </span>, historical trade data, and global HS code databases to automatically assign the most accurate classification. This reduces human error, enhances compliance, and minimizes financial risks.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">Real-Time Trade Compliance Monitoring</h2>
                    <p className="text-[#3B3C4A]">
                        With ever-changing global trade regulations, businesses struggle to stay updated on new compliance requirements. Vidatt AI Assist continuously monitors regulatory changes and updates classifications in real time, helping companies remain compliant and avoid penalties.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">Seamless Integration with Supply Chain & Procurement</h2>
                    <p className="text-[#3B3C4A]">
                        Vidatt AI Assist integrates seamlessly with  <span className='text-md font-bold text-[#3b3c4a]'> ERP, logistics, and procurement systems, </span> providing instant classification insights at every stage of the supply chain. This prevents last-minute errors and ensures that trade compliance is built into business operations.
                    </p>

                    <div className='bg-[#F6F6F6] '>
                        <a href="/product/hscode" className="block w-full md:no-underline">
                            <div className='flex justify-between items-center px-5 py-3'>
                                <h2 className="text-2xl font-bold m-0">AI Powered HS Classification at your fingertips</h2>
                                <div className='border rounded-lg border-[#D6D6D6] bg-white w-10 h-10 hidden md:flex items-center justify-center'>
                                    <LearnMore className='w-1.5 h-1.5' />
                                </div>
                            </div>
                        </a>
                    </div>


                </div>
            </div>

            <div className="relative w-full p-6 md:px-12 md:py-6" style={{
                backgroundImage: `url(${BackgroundPNG})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: '#7047EB',
            }} >
                <img
                    src={BackgroundPNG}
                    alt="Background"
                    className="absolute inset-0 w-full h-full object-cover -z-10"
                />

                <div className="max-w-4xl mx-auto relative z-10">
                    <h2 className="text-6xl text-white font-bold">The Future of HS Code Classification with AI</h2>
                    <p className="mt-2 text-sm md:text-base text-[#BDBDBD]">
                        HS code misclassification is an avoidable risk that can cost businesses time, money, and reputation.
                        Vidatt AI Assist revolutionizes trade compliance by introducing AI-powered accuracy, automation, and real-time monitoring, making international trade smoother, faster, and error-free.
                    </p>
                </div>
            </div>

            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12 flex flex-col items-center justify-center bg-gray-100 p-10 my-6 rounded-2xl shadow-md">
                <h2 className="text-3xl md:text-4xl font-bold text-gray-900 text-center">
                    Want to optimize your trade compliance process?
                </h2>
                <p className="mt-3 text-lg text-gray-500 text-center">
                    Book a live demo today and see how Vidatt AI Assist can make a difference.
                </p>
                <button className="mt-6 flex items-center gap-2 bg-white text-gray-900 font-medium px-6 py-3 rounded-lg shadow hover:shadow-lg transition" onClick={() => { setShowDialog(true) }}>
                    Book Now <ArrowUpRight size={18} className="text-blue-600" />
                </button>
            </div>

            <BlogSuggestions relatedPost={relatedPost} />

            {showDialog ?
                <Dialog
                    children={<DemoForm />}
                    header='Get Demo'
                    subHeader=''
                    onClose={() => setShowDialog(false)}
                />
                : null}
        </div >
    );
};

export default Blog3;

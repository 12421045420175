import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Navigation from './navigation';

export default function NotFound() {
    return (
        <div className='w-full h-full'>
            <Navigation />
            <div className="flex flex-col items-center justify-center h-[90vh] text-gray-700">
                <h1 className="text-4xl font-bold mb-4">😕 404 </h1>
                <p className="text-xl mb-6">Oops! Blog Post Not Found.</p>
                <Link
                    to="/blogs"
                    className="flex items-center gap-2 bg-[#4C47EB] text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition"
                >
                    <ArrowLeft size={20} />
                    Back to Blogs
                </Link>
            </div>
        </div>
    );
}

import React from 'react'
import { Link } from 'react-router-dom';

const BlogSuggestions = ({ relatedPost }) => {
    return (
        <div className="w-full p-6 md:p-12 bg-[#F7F7F8]">
            <div className=" mx-auto  max-w-7xl">
                <h2 className="text-2xl font-semibold text-gray-900">You might also like these</h2>
                {relatedPost &&
                    (<Link key={relatedPost.id} to={`/blogs/${relatedPost.id}`} className="group">
                        <div className="mt-6 flex flex-col md:flex-row items-start md:gap-10 gap-6">
                            <div className="w-full md:w-2/5">
                                <img
                                    src={relatedPost.image}
                                    alt={relatedPost.title}
                                    className="w-[560px] h-[250px] object-cover rounded-lg mb-4"
                                />
                            </div>

                            <div className="w-full md:w-3/5">
                                <p className="text-sm text-gray-600  font-medium">{relatedPost.date}</p>
                                <h3 className="text-lg md:text-xl font-semibold mt-1 text-gray-900">
                                    {relatedPost.title}
                                </h3>
                                <p className="text-gray-600 mt-2 text-sm md:text-base">
                                    {relatedPost.description}
                                </p>

                                <div className="mt-4 flex gap-2">
                                    <span className="px-3 py-1 bg-[#FFF9EB] text-[#8A6100] text-xs font-medium rounded-full">
                                        {relatedPost.categories[0]}
                                    </span>
                                    <span className="px-3 py-1 bg-[#FEECFB] text-[#A5088C] text-xs font-medium rounded-full">
                                        {relatedPost.categories[1]}
                                    </span>
                                    <span className="px-3 py-1 bg-[#F4F4F4] text-[#666666] text-xs font-medium rounded-full">
                                        {relatedPost.categories[2]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Link>
                    )}
            </div>
        </div>
    )
}

export default BlogSuggestions

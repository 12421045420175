import Blog1 from '../assets/blogs/blogsMain/blog1.png'
import Blog2 from '../assets/blogs/blogsMain/blog2.png'
import Blog3 from '../assets/blogs/blogsMain/blog3.png'

export const blogPosts = [
    {
        id: 1,
        title: 'Vidatt AI Assist: Revolutionizing Business Productivity with Generative AI',
        description: `In today's fast-paced business world, staying ahead of the curve is more important than ever. Organizations are constantly looking for smarter ways to boost productivity, streamline operations, and maintain a competitive edge. That's where Vidatt AI Assist comes in-a robust generative AI tool designed to act as a smart, reliable assistant for teams across every department, from sales and HR to IT and operations.`,
        author: 'Vidatt Data Analytics',
        date: '12 Mar 2025',
        image: Blog1,
        categories: ['GenerativeAI', 'BusinessProductivity', 'AIAutomation'],
    },
    {
        id: 2,
        title: 'Introducing Vidatt Ai Assist – Smart Automation for Smarter Businesses',
        description: `We’re excited to introduce Vidatt AI Assist, an AI-powered automation platform built to make business operations smoother, faster, and more intelligent. Whether it’s handling invoices, improving sales efficiency, or ensuring compliance, Vidatt AI Assist takes the heavy lifting off your plate so you can focus on what really matters—growing your business.`,
        author: 'Vidatt Data Analytics',
        date: '13 Mar 2025',
        image: Blog2,
        categories: ['AIAutomation', 'SmartBusiness', 'WorkflowEfficiency']
    },
    {
        id: 3,
        title: 'Understanding HS Codes and the Cost of Misclassification',
        description: `In global trade, Harmonized System (HS) codes serve as the backbone of customs classification, ensuring that products are correctly identified and taxed. These internationally recognized codes, developed by the World Customs Organization (WCO), help standardize trade operations across countries. Every product that crosses international borders must be assigned an HS code, which determines its applicable duties, taxes, and regulatory requirements.`,
        author: 'Vidatt Data Analytics',
        date: '24 Mar 2025',
        image: Blog3,
        categories: ['AIforTradeCompliance', 'HSCodeAutomation', 'GlobalTradeSolutions']
    },

    // add data for new blogs
];
import React from 'react';
import { useParams } from 'react-router-dom';
import { blogPosts } from './BlogData';
import Navigation from '../components/navigation'
import NotFound from '../components/NotFound.jsx'

// Import blog components statically
import Blog1 from '../components/blogs/Blog1.jsx';
import Blog2 from '../components/blogs/Blog2.jsx';
import Blog3 from '../components/blogs/Blog3.jsx';
import Footer from '../components/footer.js';

// Blog Component Mapping
const blogComponents = {
    1: Blog1,
    2: Blog2,
    3: Blog3,
};

export default function BlogPost() {
    const { id } = useParams();
    const post = blogPosts.find(p => p.id === Number(id));
    const BlogComponent = blogComponents[id] || (() => <NotFound />);

    if (!post) {
        return <NotFound />;
    }

    return (
        <div className='w-full h-full'>
            <Navigation />
            <BlogComponent post={post} />
            <Footer />
        </div>
    );
}
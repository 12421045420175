import React, { useState } from 'react'
// import { useNavigate } from 'react-router'

import { ReactComponent as RightArrow } from '../assets/right-arrow-icon.svg'


const DemoForm = () => {
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [requirement, setRequirement] = useState('')
    const isFormValid = name.trim() && company.trim() && requirement.trim()
    // const navigate = useNavigate()

    // const handleSubmit = event => {
    //     event.preventDefault();

    //     const myForm = event.target;
    //     const formData = new FormData(myForm);

    //     fetch("/", {
    //       method: "POST",
    //       headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //       body: new URLSearchParams(formData).toString()
    //     })
    //       .then(() => navigate("/thank-you/"))
    //       .catch(error => alert(error));
    //   };

    return (
        <div className='w-full h-full'>
            <div className='flex flex-col'>
                <form className='w-full flex flex-col justify-start items-start text-left bg-white' name="contactForm" netlify data-netlify="true" method="POST" >
                    <input type="hidden" name="form-name" value="contactForm" />
                    <div className='flex flex-col lg:flex-row gap-4 lg:gap-[2%] mt-4 w-full'>
                        <input
                            type='text'
                            name='name'
                            className='p-2 border border-[#D1D1DB] rounded-lg placeholder-[#A9A9BC] min-w-full lg:min-w-[49%]'
                            placeholder='Your Name'
                            onChange={(event) => { setName(event.target.value) }}
                            value={name}
                        />
                        <input
                            type='text'
                            className='p-2 border border-[#D1D1DB] rounded-lg placeholder-[#A9A9BC] min-w-full lg:min-w-[49%]'
                            placeholder='Your Company Name'
                            name='company'
                            value={company}
                            onChange={(event) => { setCompany(event.target.value) }}
                        />
                    </div>
                    <div className='min-w-full'>
                        <textarea
                            type='text'
                            name='request'
                            className='p-2 border border-[#D1D1DB] rounded-lg placeholder-[#A9A9BC] min-w-full min-h-[86px] mt-8'
                            placeholder='Tell us about the request'
                            value={requirement}
                            onChange={(event) => { setRequirement(event.target.value) }}
                        />
                    </div>
                    <button
                        className={`min-w-[125px] mt-4 px-8 py-2 flex flex-row items-center gap-2 text-white text-base font-sans font-semibold bg-purpleBtn rounded-lg cursor-pointer ${isFormValid ? '' : 'opacity-50 cursor-not-allowed'}`}
                        type='submit'
                        disabled={!isFormValid}
                    >
                        Send message<RightArrow className='w-4 h-4' />
                    </button>
                </form>
            </div>
        </div>
    )
}

export default DemoForm
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Blog2HeaderD from '../../assets/blogs/blog2/blog2headerD.png';
import Blog1HeaderM from '../../assets/blogs/blog1/blog1headerM.png';
import AiAssist from '../../assets/blogs/blog2/AiAssistD.png';
import AiAssistM from '../../assets/blogs/blog2/AiAssistM.png';
import { blogPosts } from '../../pages/BlogData';
import BackgroundPNG from '../../assets/blogs/blog2/future_bg.png'
import VAssist from '../../assets/blogs/blog2/Vassist.png'
import { ReactComponent as LearnMore } from '../../assets/learn_more_icon.svg'
import BlogSuggestions from './BlogSuggestions';

const features = [
    {
        title: "Saves Time",
        description: "Automates repetitive tasks, freeing up teams to focus on strategy and customer engagement. Businesses report a 50% increase in productivity after implementation"
    },
    {
        title: "Reduces Errors",
        description: "AI-powered precision minimizes costly mistakes, reducing financial and compliance risks"
    },
    {
        title: "Scales with You",
        description: "Adapts to business growth, handling increasing workloads without additional staffing costs"
    },
    {
        title: "Easy to Use",
        description: "Plug-and-play deployment means no complicated setup. Get up and running in minutes, not weeks"
    }
];
const Blog2 = ({ post }) => {
    const relatedPostId = 3;
    const relatedPost = blogPosts.find(p => p.id === relatedPostId);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="min-h-screen bg-white">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <Link to="/blogs" className="inline-flex items-center text-[#3B3C4A] hover:text-indigo-700 mb-8">
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    Back to blogs
                </Link>

                <div className="flex flex-wrap gap-2 mb-4">
                    {post.categories.map((category, idx) => (
                        <span
                            key={idx}
                            className={`text-xs px-3 py-1 rounded-full ${category === 'SmartBusiness' ? 'bg-[#EDF4FF] text-[#1619CA]' :
                                category === 'WorkflowEfficiency' ? 'bg-[#FEECFB] text-[#A5088C]' :
                                    category === 'AIAutomation' ? 'bg-[#FFF2EE] text-[#B82E00]' :
                                        'bg-gray-100 text-gray-800'
                                }`}
                        >
                            {category}
                        </span>
                    ))}
                </div>

                <h1 className="text-4xl font-bold text-gray-900 mb-4">{post.title}</h1>

                <div className="flex items-center text-sm text-gray-500 mb-8">
                    <span>{post.date}</span>
                </div>
                <img
                    src={Blog2HeaderD}
                    alt={post.title}
                    className="hidden md:block w-full h-[585px] object-cover rounded-lg mb-8"
                />

                <img
                    src={Blog1HeaderM}
                    alt={post.title}
                    className="block md:hidden w-full h-[240px] object-cover rounded-lg mb-8"
                />

                <div className="prose max-w-none">
                    <p className="text-gray-600 mb-6">We’re excited to introduce Vidatt Ai Assist, an AI-powered automation platform built to make business operations smoother, faster, and more intelligent. Whether it’s handling invoices, improving sales efficiency, or ensuring compliance, Vidatt Ai Assist takes the heavy lifting off your plate so you can focus on what really matters-growing your business.
                    </p>

                    <div className='bg-[#F6F6F6]'>
                        <a href="/product/co-pilot" className="block w-full md:no-underline">
                            <div className="flex justify-between items-center px-5 py-3  rounded-md border border-[#D6D6D6]">
                                <h2 className="text-2xl font-bold m-0">What Makes Vidatt Ai Assist Different ?</h2>
                                <div className="border rounded-lg border-[#D6D6D6] bg-white w-10 h-10 hidden md:flex items-center justify-center">
                                    <LearnMore className="w-1.5 h-1.5" />
                                </div>
                            </div>
                        </a>
                    </div>

                    <h2 className="text-2xl font-semibold mb-4">Intelligent Automation That Adapts to Your Business</h2>
                    <p className="text-gray-600 mb-6">
                        Vidatt Ai Assist isn’t just another automation tool. It’s designed to think, learn, and adapt - helping businesses cut down on manual work, avoid errors, and make smarter decisions in real time. Unlike traditional automation, which follows pre-defined rules, Vidatt Ai Assist understands patterns, analyzes context, and continuously improves based on real-world interactions. From finance teams looking to simplify invoice processing to sales teams wanting better insights, Vidatt Ai Assist fits right into your workflow with minimal setup and maximum impact.
                    </p>

                    <div className='bg-[#F6F6F6]'>
                        <a href="/product/co-pilot" className="block w-full md:no-underline">
                            <div className="flex justify-between items-center px-5 py-3  rounded-md border border-[#D6D6D6]">
                                <h2 className="text-2xl font-bold m-0">How Vidatt Ai Assist Works for You</h2>
                                <div className="border rounded-lg border-[#D6D6D6] bg-white w-10 h-10 hidden md:flex items-center justify-center">
                                    <LearnMore className="w-1.5 h-1.5" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <img
                        src={AiAssist}
                        alt={post.title}
                        className="hidden md:block w-full h-[310px] object-cover rounded-lg mb-8"
                    />

                    <img
                        src={AiAssistM}
                        alt={post.title}
                        className="block md:hidden w-full h-[380px] object-cover rounded-lg mb-8"
                    />
                    <h2 className="text-2xl font-semibold mb-4">AI-Powered Invoice Processing</h2>
                    <p className="text-gray-600">
                        Extracts key details like vendor name, invoice number, and due date with near-perfect accuracy. Flags duplicate invoices and potential fraud in real time, ensuring secure financial operations. Reduces manual data entry by over 80%, saving hours of processing time
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">Smarter Sales & CRM Insights</h2>
                    <p className="text-[#3B3C4A]">
                        Uses AI-driven analytics to identify high-value leads, predict customer behavior, and automate personalized outreach. Helps sales teams close deals faster by surfacing relevant insights and reminders, eliminating guesswork in the sales process
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">Effortless Compliance & HS Code Classification</h2>
                    <p className="text-[#3B3C4A]">
                        Accurately classifies products for international trade, preventing misclassification fines and customs delays. Automatically updates regulatory changes to ensure ongoing compliance. Reduces trade processing time while minimizing costly errors
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">Seamless Integration</h2>
                    <p className="text-[#3B3C4A]">
                        Instantly connects with your CRM, ERP, and existing enterprise systems. Deploys without disrupting current workflows, allowing teams to get started immediately with zero downtime
                    </p>

                    <div className='bg-[#F6F6F6] '>
                        <a href="/product/co-pilot" className="block w-full md:no-underline">
                            <div className='flex justify-between items-center px-5 py-3'>
                                <h2 className="text-2xl font-bold m-0">Why Businesses Love Vidatt Ai Assist?</h2>
                                <div className='border rounded-lg border-[#D6D6D6] bg-white w-10 h-10 hidden md:flex items-center justify-center'>
                                    <LearnMore className='w-1.5 h-1.5' />
                                </div>
                            </div>
                        </a>

                        <div className='w-full border border-0.5  border-[#D9D9D9]' ></div>
                        <div className="max-w-4xl mx-auto px-4 py-2 sm:px-6 lg:px-8">
                            <div className="space-y-1">
                                {features.map((feature, index) => (
                                    <div key={index} className="relative">
                                        <p className="text-xl font-bold tracking-tight text-gray-900 mb-3">
                                            {feature.title}
                                        </p>
                                        <p className="text-md text-[#3B3C4A] leading-relaxed">
                                            {feature.description}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="relative w-full p-6 md:px-12 md:py-6" style={{
                backgroundImage: `url(${BackgroundPNG})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: '#7047EB',
            }} >
                <img
                    src={BackgroundPNG}
                    alt="Background"
                    className="absolute inset-0 w-full h-full object-cover -z-10"
                />

                <div className="max-w-4xl mx-auto relative z-10">
                    <h2 className="text-6xl text-white font-bold">The Future of Work Starts Now</h2>
                    <p className="mt-2 text-sm md:text-base text-[#BDBDBD]">
                        Vidatt Ai Assist is more than just automation - it's an intelligent transformation. By embedding AI-powered decision-making into everyday processes, businesses can unlock new efficiencies, reduce operational costs, and drive sustained growth
                    </p>
                </div>
            </div>

            <div className='w-full '>
                <div className="flex flex-col md:flex-row gap-2 md:gap-[6%] xl:gap-[64px] w-full md:max-w-7xl px-4 md:w-[96%] xl:w-[80%] mx-auto mt-4  md:my- [20px]">
                    <img
                        src={VAssist}
                        alt="Background"
                        className="w-full h-auto md:w-[45%] xl:max-w-[643px] rounded-lg mt-3"
                    />
                    <div className="w-full px-4 py-2 md:p-0 md:py-0 md:w-[48%] flex flex-col justify-center items-start text-left gap-3 bg-white rounded-lg">
                        <div className="font-bold text-2xl md:text-[24px] xl:text-[40px] xl:leading-[60px] text-[#0D121F]">
                            The future of work is AI-driven. Are you ready to take the leap?
                        </div>
                        <div className="font-normal text-sm xl:text-base text-[#596780] opacity-65">
                            Experience the future of Conversational AI automation
                        </div>
                        <div className="w-fit font-400 flex bg-white border border-[#E7E7E7] text-[#242C3D] px-6 py-3 text-base rounded-md cursor-pointer justify-center items-center gap-2"
                            onClick={() => window.location.href = "/product/co-pilot"} >
                            <p>Learn More  </p>
                            <LearnMore className='w-2 h-2' />
                        </div>
                    </div>
                </div>
            </div>

            <BlogSuggestions relatedPost={relatedPost} />
        </div >
    );
};

export default Blog2;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Blog1HeaderD from '../../assets/blogs/blog1/blog1headerD.png';
import Blog1HeaderM from '../../assets/blogs/blog1/blog1headerM.png';
import LighteningFastD from '../../assets/blogs/blog1/LighteningDataD.png';
import LighteningFastM from '../../assets/blogs/blog1/LighteningDataM.png';
import SimplifyTradeD from '../../assets/blogs/blog1/simplyTradeD.png';
import SimplifyTradeM from '../../assets/blogs/blog1/simplyTradeM.png';
import SalesD from '../../assets/blogs/blog1/SalesD.png';
import SalesM from '../../assets/blogs/blog1/SalesM.png';
import { blogPosts } from '../../pages/BlogData';
import { ReactComponent as LearnMore } from '../../assets/learn_more_icon.svg'
import BlogSuggestions from './BlogSuggestions';


const Blog1 = ({ post }) => {
    const relatedPostId = 2;
    const relatedPost = blogPosts.find(p => p.id === relatedPostId);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="min-h-screen bg-white">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <Link to="/blogs" className="inline-flex items-center text-[#3B3C4A] hover:text-indigo-700 mb-8">
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    Back to blogs
                </Link>

                <div className="flex flex-wrap gap-2 mb-4">
                    {post.categories.map((category, idx) => (
                        <span
                            key={idx}
                            className={`text-xs px-3 py-1 rounded-full ${category === 'GenerativeAI' ? 'bg-[#EDF4FF] text-[#1619CA]' :
                                category === 'BusinessProductivity' ? 'bg-[#FEECFB] text-[#A5088C]' :
                                    category === 'AIAutomation' ? 'bg-[#FFF2EE] text-[#B82E00]' :
                                        'bg-gray-100 text-gray-800'
                                }`}
                        >
                            {category}
                        </span>
                    ))}
                </div>

                <h1 className="text-4xl font-bold text-gray-900 mb-4">{post.title}</h1>

                <div className="flex items-center text-sm text-gray-500 mb-8">
                    <span>{post.date}</span>
                </div>
                <img
                    src={Blog1HeaderD}
                    alt={post.title}
                    className="hidden md:block w-full h-[595px] object-cover rounded-lg mb-8"
                />

                <img
                    src={Blog1HeaderM}
                    alt={post.title}
                    className="block md:hidden w-full h-[240px] object-cover rounded-lg mb-8"
                />

                <div className="prose max-w-none">
                    <p className="text-gray-600 mb-6">In today's fast-paced business world, staying ahead of the curve is more important than ever. Organizations are constantly looking for smarter ways to boost productivity,
                        streamline operations, and maintain a competitive edge. That's where <a href='/product/co-pilot'> Vidatt AI Assist </a>  comes in-a robust generative AI tool designed to act as a smart, reliable assistant for teams across every department, from sales and HR to IT and operations.
                    </p>

                    <div className='bg-[#F6F6F6] border border-[#F6F6F6] rounded-md p-1'>
                        <a href="/product/co-pilot" className="block w-full md:no-underline">
                            <div className='flex justify-between items-center p-2'>
                                <h2 className="text-2xl font-bold m-0">Why  Vidatt Ai Assist?</h2>
                                <div className='border rounded-lg border-[#D6D6D6] bg-white w-10 h-10 hidden md:flex items-center justify-center'>
                                    <LearnMore className='w-1.5 h-1.5' />
                                </div>
                            </div>
                        </a>
                    </div>

                    <h2 className="text-2xl font-semibold mb-4">Enhancing Employee Productivity with AI</h2>
                    <p className="text-gray-600 mb-6">
                        Vidatt AI Assist is more than just a digital assistant. It takes on the repetitive and time-consuming tasks that often bog down employees, offering real-time insights and intelligent recommendations to support quick, informed decision-making. Whether it's automating workflows or providing tailored suggestions based on company-specific data, this AI tool helps employees focus on what truly matters-driving results and innovation.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">Breaking Down Information Silos with Organizational Search</h2>
                    <p className="text-gray-600 mb-6">
                        One of the standout features of Vidatt AI Assist is its advanced search capability. Imagine being able to pull up any piece of knowledge, file, or data from across your entire business infrastructure in seconds. It not only makes information more accessible but also bridges gaps between different systems and departments, making sure that critical information is always just a quick search away.
                    </p>
                    <div className='bg-[#F6F6F6] rounded-md p-1'>
                        <a href="/product/co-pilot#use-cases" className="block w-full md:no-underline hover:text-blue-800 p-2">
                            <div className='flex justify-between items-center p-2'>
                                <h2 className="text-2xl font-bold m-0">Usecases of Vidatt AI Assist for different departments within an organization</h2>
                                <div className='border hidden md:flex rounded-lg border-[#D6D6D6] bg-white w-12 h-10  items-center justify-center'>
                                    <LearnMore className='w-1.5 h-1.5' />
                                </div>
                            </div>
                        </a>
                    </div>
                    <h2 className="text-2xl font-semibold mb-4">Boosting Sales Efficiency with an AI-Based Sales Assistant</h2>
                    <p className="text-gray-600">
                        Sales teams thrive on efficiency and agility. Vidatt AI Assist's AI-based Sales Assistant is designed to do just that-boost engagement, enhance productivity, and provide sales reps with instant access to vital insights. Instead of getting bogged down by repetitive admin tasks, sales professionals can concentrate on building meaningful relationships with prospects, backed by precise information and timely recommendations.
                    </p>
                    <img
                        src={SalesD}
                        alt={post.title}
                        className="hidden md:block w-full h-[300px] object-cover rounded-lg mb-8"
                    />

                    <img
                        src={SalesM}
                        alt={post.title}
                        className="block md:hidden w-full h-[380px] object-cover rounded-xl mb-8"
                    />
                    <h2 className="text-2xl font-semibold mb-4">Streamlining Financial Operations with the AI Invoice Assistant</h2>
                    <p className="text-gray-600">
                        Financial management is another area where Vidatt AI Assist shines. The AI-based Invoice Assistant offers powerful tools for invoice retrieval and financial analysis. With features like automated data extraction and smart search capabilities, businesses can handle financial operations with accuracy and ease, reducing manual work and enhancing compliance.
                    </p>
                    <img
                        src={LighteningFastD}
                        alt={post.title}
                        className="hidden md:block w-full h-[370px] object-cover rounded-lg mb-8"
                    />

                    <img
                        src={LighteningFastM}
                        alt={post.title}
                        className="block md:hidden w-full h-[175px] object-cover rounded-lg mb-8"
                    />
                    <h2 className="text-2xl font-semibold mb-4">Simplifying Trade Compliance with HS Code Classification</h2>
                    <p className="text-[#3B3C4A]">
                        Navigating global trade regulations can be complex, but Vidatt AI Assist makes it simpler with its <span className='text-[#3B3C4A] text-bold' >HS Code Classification tool</span> . By accurately matching products with the correct HS codes, businesses can avoid costly mistakes, minimize legal risks, and ensure smoother customs processes. It’s a game-changer for companies dealing with international trade, providing peace of mind when it comes to compliance.
                    </p>
                    <img
                        src={SimplifyTradeD}
                        alt={post.title}
                        className="hidden md:block w-full h-[612px] object-cover rounded-lg mb-8"
                    />
                    <img
                        src={SimplifyTradeM}
                        alt={post.title}
                        className="block md:hidden w-full h-[240px] object-cover rounded-lg mb-8"
                    />
                    <a href="/product/hscode" target="_blank" rel="noopener noreferrer" className="underline decoration-blue-800 hover:text-blue-800">
                        <p className='text-center text-[#1619CA] text-lg'>Know more about how Vidatt is simplifying Global Trade</p>
                    </a>

                    <h2 className="text-2xl font-semibold mb-4">Transforming HR Processes with Intelligent Automation</h2>
                    <p className="text-gray-600">
                        Vidatt AI Assist also brings transformative benefits to human resources. From recruitment and onboarding to managing employee engagement and compliance, it automates routine HR tasks, allowing teams to focus on strategic priorities. By creating a more streamlined and responsive HR experience, businesses can build stronger, more agile teams.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Optimizing Marketing and Website Performance</h2>
                    <p className="text-gray-600">
                        For marketing teams, Vidatt AI Assist offers real-time analytics and insights to drive data-backed decisions. It helps monitor website performance, optimize digital campaigns, and even automate copy creation for omnichannel strategies. With instant alerts on key performance metrics, marketers can adapt quickly to changes and maximize campaign effectiveness.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Revolutionizing IT Support and Operations</h2>
                    <p className="text-gray-600">
                        Operational efficiency often hinges on effective IT support. Vidatt AI Assist serves as a central knowledge hub, helping IT teams manage provisioning tasks and enhance support operations. By offering quick access to real-time data and automating level 1 support functions, the tool not only improves response times but also boosts overall employee satisfaction.
                    </p>
                </div>
            </div>
            <div className="w-full bg-[#3D3D3D] p-6 md:px-12 md:py-6 text-white">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-lg font-semibold">Conclusion</h2>
                    <p className="mt-2 text-sm md:text-base text-[#E0E0E0]">
                        Vidatt AI Assist is more than just a tool—it’s a strategic asset for businesses aiming to harness the power of
                        generative AI. By automating mundane tasks, improving decision-making, and ensuring compliance, it helps
                        companies focus on growth and innovation. As the demand for smart business solutions grows, Vidatt AI Assist
                        offers a reliable and forward-thinking approach to staying ahead in an ever-evolving market.
                    </p>
                </div>
            </div>

            <BlogSuggestions relatedPost={relatedPost} />
        </div>
    );
};

export default Blog1;

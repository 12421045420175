import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, ArrowUpRight, Search, X } from 'lucide-react';
import Navigation from '../components/navigation'
import { ReactComponent as BlogNotFound } from '../assets/Blog_search_not_found.svg'
import { blogPosts } from './BlogData';
import Footer from '../components/footer';

export default function Blogs() {
  // const [email, setEmail] = useState('');
  // const [currentPage, setCurrentPage] = useState(1);
  // const totalPages = 10;

  // const handleSubscribe = (e) => {
  //   e.preventDefault();
  //   alert(`Subscribed with email: ${email}`);
  //   setEmail('');
  // };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  //   window.scrollTo(0, 0);
  // };
  const [searchQuery, setSearchQuery] = useState('');
  // const [activeFilter, setActiveFilter] = useState('all');
  const sortedPosts = blogPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
  // const featuredPost2 = sortedPosts[1];
  const featuredPost = sortedPosts[0];
  const recentPosts = sortedPosts.slice(1, 3);



  // const filters = [
  //   { id: 'all', label: 'All', count: 25 },
  //   { id: 'ai-assist', label: 'AI Assist', count: 14 },
  //   { id: 'proenvi', label: 'Proenvi', count: 11 },
  //   { id: 'hs-code', label: 'HS Code', count: 11 },
  //   { id: 'rd', label: 'R&D', count: 11 },
  // ];

  const highlightJSX = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <mark key={index} className="bg-yellow-200">{part}</mark>
      ) : (
        part
      )
    );
  };

  const filteredPosts = sortedPosts.filter(
    (post) =>
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.description.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div className='w-full h-full'>
      <Navigation />
      <div className="min-h-screen bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center mb-12">
            <p className="text-indigo-600 font-medium mb-2">Our blogs</p>
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Our Stories and behind the scenes</h1>

            {/* Subscription Form */}
            {/* <p className="text-gray-500 max-w-2xl mx-auto">
              Subscribe to learn about new product features, the latest in technology, solutions, and updates.
            </p> */}

            {/* <div className="mt-8 max-w-md mx-auto">
              <form onSubmit={handleSubscribe} className="flex flex-col sm:flex-row gap-2">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className="bg-[#4c47eb] text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors"
                >
                  Subscribe
                </button>
              </form>
              <p className="text-xs text-gray-500 mt-2">
                We care about your data in our <a href="#" className="underline">privacy policy</a>
              </p>
            </div> */}
          </div>

          <div className="py-4">
            <div className="flex md:flex-row flex-col gap-1 justify-between items-center w-full">
              <h1 className="text-xl font-bold text-gray-900">{searchQuery ? "Results" : "Recent blog posts"}</h1>
              <div className="relative w-80">
                <input
                  type="text"
                  placeholder="Search for a blog"
                  className="w-80 pl-4 pr-10 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {searchQuery ? <>
                  <button
                    onClick={() => setSearchQuery('')}
                    className="absolute right-3 top-1/2 -translate-y-1/2"
                  >
                    <X className="h-5 w-5 text-gray-400" />
                  </button>
                </>
                  : <> <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} /></>
                }
              </div>
            </div>
          </div>

          {searchQuery ? <>
            <div className=" mx-auto">
              <div className="flex gap-2 flex-wrap">
                {/* {filters.map((filter) => (
                  <button
                    key={filter.id}
                    onClick={() => setActiveFilter(filter.id)}
                    className={`border border-[#1F31F8] rounded-xl p-1 ${activeFilter === filter.id ? 'bg-blue-100 text-blue-800' : 'bg-white text-gray-600 hover:bg-gray-50'}`}
                  >
                    {filter.label} <span className="ml-1 text-gray-500">{filter.count}</span>
                  </button>
                ))} */}
              </div>

              <div className="space-y-4">
                {filteredPosts.length > 0 ? (
                  filteredPosts.map((post, idx) => (
                    <Link key={post.id} to={`/blogs/${post.id}`} className="group">
                      <div key={idx} className="bg-white rounded-lg shadow-sm py-4 flex flex-col md:flex-row gap-6">
                        <div className="w-full">
                          <img src={post.image} alt={post.title} className="rounded-lg w-full" />
                        </div>

                        <div className="w-full flex flex-col">
                          <div className="text-sm text-[#4C47EB] mb-2">{post.date}</div>
                          <h3 className="text-xl font-semibold mb-2 leading-tight flex justify-between items-center">
                            <span>{highlightJSX(post.title, searchQuery)}</span>
                            <ArrowUpRight className="text-[#1A1A1A] group-hover:text-blue-600" size={24} />
                          </h3>

                          <p className="text-gray-600 mb-4">
                            <span>{highlightJSX(post.description, searchQuery)}</span>
                          </p>

                          <div className="flex flex-wrap gap-2">
                            {post.categories.map((category, catIdx) => (
                              <span key={catIdx} className="px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-600" style={{ backgroundColor: catIdx === 0 ? '#FFF9EB' : '#FEECFB', color: catIdx === 0 ? '#8A6100' : '#A5088C' }}>
                                {category}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div className="flex flex-col items-center justify-center text-gray-500">
                    <BlogNotFound className="w-full max-w-[350px] md:max-w-[850px] h-auto" />
                  </div>

                )}
              </div>
            </div>
          </>
            : <>
              {/* Recent Blog post desktop view  */}
              <div className="hidden md:block mb-4">
                {/* <div className="flex justify-between items-center mb-7 w-full">
                  <h1 className="text-xl font-bold text-gray-900">Recent blog posts</h1>
                  <div className="relative w-80">
                    <input
                      type="text"
                      placeholder="Search for a blog"
                      className="w-80 pl-4 pr-10 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  </div>
                </div> */}

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                  {featuredPost && (
                    <Link key={featuredPost.id} to={`/blogs/${featuredPost.id}`} className="group">
                      <div className="col-span-2 lg:col-span-1">
                        <div className="group cursor-pointer">
                          <div className="relative h-[200px] mb-1">
                            <img src={featuredPost.image} alt={featuredPost.title} className="w-full h-full object-cover rounded-lg" />
                          </div>
                          <p className="text-gray-600 mb-4">{featuredPost.date}</p>
                          <div className="flex items-start justify-between group">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4 group-hover:text-indigo-600">{featuredPost.title}</h2>
                            <ArrowUpRight className="text-[#1A1A1A] group-hover:text-blue-600" size={24} />
                          </div>
                          <p className="text-gray-600 mb-6">
                            {featuredPost.description.split(" ").slice(0, 22).join(" ")}...
                          </p>
                          <div className="flex gap-2">
                            {featuredPost?.categories?.map((tag, index) => (
                              <span key={index} className="px-3 py-1 rounded-full text-sm" style={{ backgroundColor: index === 0 ? '#EEF4FF' : '#FDF2FA', color: index === 0 ? '#3538CD' : '#C11574' }}>
                                {tag}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                  {/* {featuredPost2 && (
                    <Link key={featuredPost2.id} to={`/blogs/${featuredPost2.id}`} className="group">
                      <div className="col-span-2 lg:col-span-1">
                        <div className="group cursor-pointer">
                          <div className="relative h-[200px] mb-1">
                            <img src={featuredPost2.image} alt={featuredPost2.title} className="w-full h-full object-cover rounded-lg" />
                          </div>
                          <p className="text-gray-600 mb-4">{featuredPost2.date}</p>
                          <div className="flex items-start justify-between group">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4 group-hover:text-indigo-600">{featuredPost2.title}</h2>
                            <ArrowUpRight className="text-[#1A1A1A] group-hover:text-blue-600" size={24} />
                          </div>
                          <p className="text-gray-600 mb-6">{featuredPost2.description}</p>
                          <div className="flex gap-2">
                            {featuredPost2?.categories?.map((tag, index) => (
                              <span key={index} className="px-3 py-1 rounded-full text-sm" style={{ backgroundColor: index === 0 ? '#EEF4FF' : '#FDF2FA', color: index === 0 ? '#3538CD' : '#C11574' }}>
                                {tag}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Link>
                  )} */}

                  <div className="col-span-2 lg:col-span-1 space-y-10">
                    {recentPosts?.map((post) => (
                      <Link key={post.id} to={`/blogs/${post.id}`} className="group">
                        <div key={post.id} className="flex gap-6 group cursor-pointer">
                          <div className="relative w-[300px] h-[200px] flex-shrink-0">
                            <img src={post.image} alt={post.title} className="w-full h-full object-cover rounded-lg p-2" />
                          </div>
                          <div className="flex-1">
                            <div className="flex items-center gap-3 text-sm text-[#3B3C4A] mb-2">
                              <span>{post.date}</span>
                            </div>
                            <div className="flex items-start justify-between group mb-2">
                              <h3 className="text-xl font-semibold text-gray-900 group-hover:text-indigo-600">{post.title}</h3>
                            </div>
                            <p className="text-gray-600 mb-2">
                              {post.description.split(" ").slice(0, 10).join(" ")}...
                            </p>
                            <div className="flex gap-2">
                              {post?.categories?.map((tag, index) => (
                                <span key={index} className="px-3 py-1 rounded-full text-sm" style={{ backgroundColor: index === 0 ? '#EEF4FF' : '#FDF2FA', color: index === 0 ? '#3538CD' : '#C11574' }}>
                                  {tag}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              {/* Recent Blog Posts Mobile */}
              <div className="mb-16 block md:hidden"> {/* Visible on mobile only */}
                {/* <h2 className="text-xl font-semibold text-gray-900 mb-6">Recent blog posts</h2>
                <div className="relative w-80 mb-4">
                  <input
                    type="text"
                    placeholder="Search for a blog"
                    className="w-80 pl-4 pr-10 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                </div> */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {featuredPost && (
                    <Link key={featuredPost.id} to={`/blogs/${featuredPost.id}`} className="group">
                      <div className="flex flex-col h-full">
                        <img
                          src={featuredPost.image}
                          alt={featuredPost.title}
                          className="w-full h-64 object-cover rounded-lg mb-4"
                        />
                        <div className="flex items-center text-sm text-gray-500 mb-2">
                          <span>{featuredPost.date}</span>
                        </div>
                        <h3 className="text-lg font-semibold mb-2 flex justify-between items-start group-hover:text-indigo-600 transition-colors">
                          {featuredPost.title}
                          <ArrowRight className="h-5 w-5 text-gray-400" />
                        </h3>
                        <p className="text-gray-600 mb-4">{featuredPost.description}</p>
                        <div className="mt-auto flex flex-wrap gap-2">
                          {featuredPost?.categories?.map((category, idx) => (
                            <span
                              key={idx}
                              className={`text-xs px-3 py-1 rounded-full ${category === 'AIAutomation' ? 'bg-indigo-100 text-indigo-800' :
                                category === 'SmartBusiness' ? 'bg-blue-100 text-blue-800' :
                                  category === 'WorkflowEfficiency' ? 'bg-orange-100 text-orange-800' :
                                    category === 'GenerativeAI' ? 'bg-green-100 text-green-800' :
                                      category === 'BusinessProductivity' ? 'bg-[#FEECFB] text-[#A5088C]' :
                                        'bg-[#F5F5F5] text-gray-800'
                                }`}
                            >
                              {category}
                            </span>

                          ))}
                        </div>
                      </div>
                    </Link>
                  )}
                  {recentPosts.map((post) => (
                    <Link key={post.id} to={`/blogs/${post.id}`} className="group">
                      <div className="flex flex-col h-full">
                        <img
                          src={post.image}
                          alt={post.title}
                          className="w-full h-64 object-cover rounded-lg mb-4"
                        />
                        <div className="flex items-center text-sm text-gray-500 mb-2">
                          <span>{post.date}</span>
                        </div>
                        <h3 className="text-lg font-semibold mb-2 flex justify-between items-start group-hover:text-indigo-600 transition-colors">
                          {post.title}
                          <ArrowRight className="h-5 w-5 text-gray-400" />
                        </h3>
                        <p className="text-gray-600 mb-4">{post.description}</p>
                        <div className="mt-auto flex flex-wrap gap-2">
                          {post?.categories?.map((category, idx) => (
                            <span
                              key={idx}
                              className={`text-xs px-3 py-1 rounded-full ${category === 'AIAutomation' ? 'bg-indigo-100 text-indigo-800' :
                                category === 'SmartBusiness' ? 'bg-blue-100 text-blue-800' :
                                  category === 'WorkflowEfficiency' ? 'bg-orange-100 text-orange-800' :
                                    category === 'GenerativeAI' ? 'bg-green-100 text-green-800' :
                                      category === 'BusinessProductivity' ? 'bg-red-100 text-red-800' :
                                        'bg-[#F5F5F5] text-gray-800'
                                }`}
                            >
                              {category}
                            </span>
                          ))}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>

              {/* All Blog Posts */}
              <div>
                <h2 className="text-xl font-semibold text-gray-900 mb-6 mt-8">All blog posts</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {blogPosts.map((post) => (
                    <Link key={post.id} to={`/blogs/${post.id}`} className="group">
                      <div className="flex flex-col h-full">
                        <img
                          src={post.image}
                          alt={post.title}
                          className="w-full h-48 object-cover rounded-lg mb-4"
                        />
                        <div className="flex items-center text-sm text-gray-500 mb-2">
                          <span>{post.date}</span>
                        </div>
                        <h3 className="text-lg font-semibold mb-2 flex justify-between items-start group-hover:text-indigo-600 transition-colors">
                          {post.title}
                          <ArrowUpRight className="text-[#1A1A1A] group-hover:text-blue-600" size={24} />
                        </h3>
                        <p className="text-gray-600 mb-4">{post.description}</p>
                        <div className="mt-auto flex flex-wrap gap-2">
                          {post.categories.map((category, idx) => (
                            <span
                              key={idx}
                              className={`text-xs px-3 py-1 rounded-full ${category === 'Design' ? 'bg-indigo-100 text-indigo-800' :
                                category === 'Research' ? 'bg-blue-100 text-blue-800' :
                                  category === 'Presentation' ? 'bg-orange-100 text-orange-800' :
                                    category === 'BusinessProductivity' ? 'bg-[#FEECFB] text-[#A5088C]' :
                                      category === 'AIAutomation' ? 'bg-[#FFF2EE] text-[#B82E00]' :
                                        category === 'AIforTradeCompliance' ? 'bg-[#FFF2EE] text-[#B82E00]' :
                                          category === 'HSCodeAutomation' ? 'bg-[#FEECFB] text-[#A5088C]' :
                                            category === 'GlobalTradeSolutions' ? 'bg-orange-100 text-orange-800' :
                                              category === 'GenerativeAI' ? 'bg-[#EDF4FF] text-[#1619CA]' :
                                                'bg-[#F5F5F5] text-gray-800'
                                }`}
                            >
                              {category}
                            </span>
                          ))}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>

                {/* Pagination */}
                {/* <div className="mt-12 flex flex-col sm:flex-row items-center justify-between">
              <button onClick={() => handlePageChange(Math.max(1, currentPage - 1))} disabled={currentPage === 1} className="flex items-center text-gray-600 mb-4 sm:mb-0" >
                <ArrowLeft className="h-4 w-4 mr-2" />
                Previous
              </button>
              <div className="flex items-center space-x-1">
                {[1, 2, 3].map((page) => (
                  <button key={page} onClick={() => handlePageChange(page)}  className={`w-8 h-8 flex items-center justify-center rounded-md ${currentPage === page s? 'bg-[#000000] text-white' : 'text-gray-600 hover:bg-[#F5F5F5]' }`} >
                    {page}
                  </button>
                ))}
                <span className="text-gray-600">...</span>
                {[8, 9, 10].map((page) => (
                  <button key={page}  onClick={() => handlePageChange(page)} className={`w-8 h-8 flex items-center justify-center rounded-md ${currentPage === page ? 'bg-gray-900 text-white' : 'text-gray-600 hover:bg-[#F5F5F5]' }`} >
                    {page}
                  </button>
                ))}
              </div>
              <button onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))} disabled={currentPage === totalPages} className="flex items-center text-gray-600 mt-4 sm:mt-0">
                Next
                <ChevronRight className="h-4 w-4 ml-2" />
              </button>
            </div> */}
              </div>
            </>
          }
        </div>
      </div>
      <Footer />
    </div >
  );
}
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { React, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/home'; // Eagerly loaded
import { lazyWithPreload } from 'react-lazy-with-preload';
import { ReactComponent as BackToTop } from './assets/back-to-top.svg';
import { Suspense } from 'react'; // For fallback UI during lazy loading
import Blogs from './pages/Blogs';
import BlogPosts from './pages/BlogPosts';

// Lazy-loaded components
const About = lazyWithPreload(() => import('./pages/about'));
const ContactUs = lazyWithPreload(() => import('./pages/contactUs'));
const FireDetection = lazyWithPreload(() => import('./pages/fireDetection'));
const CoPilot = lazyWithPreload(() => import('./pages/coPilot'));
const InspectraX = lazyWithPreload(() => import('./pages/inspectraX'));
const Raksul = lazyWithPreload(() => import('./pages/raksul'));
const MilkStrip = lazyWithPreload(() => import('./pages/milkstrip'));
const Services = lazyWithPreload(() => import('./pages/services'));
const Products = lazyWithPreload(() => import('./pages/products'));
const HSCode = lazyWithPreload(() => import('./pages/hsCode'));

// Custom theme
const theme = createTheme({
  typography: {
    fontFamily: 'Satoshi, Arial, sans-serif',
  },
});

function App() {
  const [scrollYPosition, setScrollYPosition] = useState(0);

  // Handle scroll to show/hide Back-to-Top button
  const handleScroll = () => {
    const newScrollYPosition = window.pageYOffset;
    setScrollYPosition(newScrollYPosition);
  };

  // Redirect for undefined routes
  function NotFoundRedirect() {
    const navigate = useNavigate();
    useEffect(() => {
      navigate('/home');
    }, [navigate]);
    return null;
  }

  // Scroll listener setup and cleanup
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Preload pages after Home has loaded
  useEffect(() => {
    const preloadPages = () => {
      About.preload();
      ContactUs.preload();
      FireDetection.preload();
      CoPilot.preload();
      InspectraX.preload();
      Raksul.preload();
      MilkStrip.preload();
      Services.preload();
      Products.preload();
      HSCode.preload();
    };
    preloadPages();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="relative">
        <Router>
          <Suspense fallback={<div className="flex justify-center items-center min-h-screen">Loading...</div>}>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/about-us" element={<About />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/blogs" element={<Blogs />} />
              <Route exact path="/product/proenvAI" element={<FireDetection />} />
              <Route exact path="/product/co-pilot" element={<CoPilot />} />
              <Route exact path="/product/inspectra-x" element={<InspectraX />} />
              <Route exact path="/services/raksul" element={<Raksul />} />
              <Route exact path="/services/milkstrip" element={<MilkStrip />} />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/product/hscode" element={<HSCode />} />
              <Route exact path="/blogs/:id" element={<BlogPosts />} />
              <Route path="*" element={<NotFoundRedirect />} />
            </Routes>
          </Suspense>
        </Router>

        {/* Back-to-Top Button */}
        {scrollYPosition > 400 && (
          <BackToTop
            className="fixed right-2 md:right-4 xl:right-10 bottom-20 cursor-pointer"
            onClick={() => window.scrollTo(0, 0)}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
